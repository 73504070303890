body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
figure,
figcaption,
th,
td,
span {
	margin: 0;
	padding: 0;
	outline: none;
}
a>img {
	cursor: pointer;
}

a>div {
	cursor: pointer;
}

a>p {
	cursor: pointer;
}

a>h5 {
	cursor: pointer;
}

a>h4 {
	cursor: pointer;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

fieldset,
img {
	border: 0;
	vertical-align: middle;
	/* display: block; */
}

address,
caption,
cite,
code,
dfn,
em,
/* strong, */
th,
var {
	font-style: normal;
	font-weight: normal;
}

ol,
ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
li{
	list-style: inherit;
}

caption,
th {
	text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
}

a,
input,
button,
select,
textarea {
	outline: none;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	border-radius: 0;
	line-height: normal;
}

abbr,
acronym {
	border: 0;
}

a {
	cursor: pointer;
}
html,body{
	/* scroll-behavior: smooth; */
}
body {
	font-family: "微软雅黑", "PingFang SC", Arial !important;
	font-size: 16px;
}
@font-face {
	font-family: 'Alimama_ShuHeiTi_Bold';
	src: url('../layui/font/iconfont.woff2') format('truetype');
}
@font-face {
	font-family: 'HelveticaNowText-Light';
	src: url('../layui/font/iconfont.woff2') format('truetype');
}
@font-face {
	font-family: 'Arial_Bold';
	src: url('../layui/font/iconfont.woff2') format('truetype');
}
@font-face {
	font-family: 'Arial';
	src: url('../layui/font/iconfont.woff2') format('truetype');
}


.clearfix:after {
	content: "";
	height: 0;
	display: block;
	clear: both;
}

.fl {
	float: left;
}

.fr {
	float: right;
}

a {
	text-decoration: none;
}

.flexBetween {
	display: -webkit-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.flexStart {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	display: -webkit-flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.flexCenter {
	display: -webkit-flex;
	/* Safari */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.flexEnd {
	display: -webkit-flex;
	/* Safari */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.ellipsis {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
	text-align: justify;
	text-justify: distribute-all-lines;
}

.ellipsis2 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-align: justify;
	text-justify: distribute-all-lines;
}

.ellipsis3 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
	text-align: justify;
	text-justify: distribute-all-lines;
}

.ellipsis4 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;
	text-align: justify;
	text-justify: distribute-all-lines;
}

.ellipsis5 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	overflow: hidden;
	text-align: justify;
	text-justify: distribute-all-lines;
}

.ellipsis6 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 6;
	overflow: hidden;
	text-align: justify;
	text-justify: distribute-all-lines;
}

.l_scrollx {
	overflow: auto;
	overflow-x: scroll;
	overflow-y: auto;
	padding-right: 10px;
}

.l_scrolly {
	overflow: auto;
	overflow-x: hidden;
	overflow-y: auto;
	padding-right: 10px;
}

.l_scroll::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 1px;
	/*高宽分别对应横竖滚动条的尺寸*/
	height: 2px;
}

.l_scroll::-webkit-scrollbar-thumb {
	/*滚动条里面小方块*/
	border-radius: 2px;
	/* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
	background: rgba(0, 0, 0, 0.7);
}

.l_scroll::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	/* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
	border-radius: 2px;
	background: rgba(0, 0, 0, 0.1);
}

.layerVideo {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.5);
	display: none;
}

.layerVideo .box {
	width: min(8rem,80vw);
	position: absolute;
	top: 50%;
	left: 50%;
	max-height: 60vh;
	-ms-transform: translateX(-50%) translateY(-50%);
	    transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
}

.layerVideo .box video {
	width: 100%;
}

.layerVideo .box span {
	position: absolute;
	right: -32px;
	top: -32px;
	color: #fff;
	font-size: 32px;
	font-weight: bold;
	cursor: pointer;
}

.input::-webkit-input-placeholder {
	color: #999;
	font-size: 16px;
}

.input::-moz-placeholder {
	color: #999;
	font-size: 16px;
}

.input:-moz-placeholder {
	color: #999;
	font-size: 16px;
}

.input:-ms-input-placeholder {
	color: #999;
	font-size: 16px;
}

.textarea::-webkit-input-placeholder {
	color: #999;
	font-size: 16px;
}

.textarea::-moz-placeholder {
	color: #999;
	font-size: 16px;
}

.textarea:-moz-placeholder {
	color: #999;
	font-size: 16px;
}

.textarea:-ms-input-placeholder {
	color: #999;
	font-size: 16px;
}

.lpk_phone {
	display: none;
}
.lpk_ipad{
	display: none;
}
.MobileMain {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	background: #000;
	display: none;
	transition: .4s , backdrop-filter 0s;
  }
  .HeaderMobile.active{
	background: rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(10px);
  }
  .HeaderMobile {
	/* border-bottom: 1px solid #f5f5f5; */
	/* display: none; */
	/* box-shadow: 0px 01px 0px 1px #f5f5f5; */
  }
  .HeaderMobile .box {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	padding: 10px 5vw;
  }
  .HeaderMobile .box .logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	    -ms-flex-align: center;
	        align-items: center;
  }
  .HeaderMobile .box .logo a {
	display: block;
	color:#9cb5d3;
  }
  .HeaderMobile .box .logo a img {
	height: 32px;
  }
  .HeaderMobile .box .button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	    -ms-flex-align: center;
	        align-items: center;
  }
  .HeaderMobile .box .button .search p svg {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
  }
  .HeaderMobile .box .button .search p svg path{
	fill: #fff;
  }
  .HeaderMobile .box .button .search .Close {
	display: none;
  }
  .HeaderMobile .box .button .search .active .Show {
	display: none;
  }
  .HeaderMobile .box .button .search .active .Close {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
  }
  .HeaderMobile .box .button .word {
	font-size: 14px;
	font-weight: bold;
  }
  .HeaderMobile .box .button .word a {
	color: #008022;
	text-decoration: none;
  }
  .HeaderMobile .box .button .word a svg {
	display: inline-block;
	vertical-align: sub;
	animation: CallUp 0.5s ease 0s infinite;
	-webkit-animation: CallUp 0.5s ease 0s infinite;
	-moz-animation: CallUp 0.5s ease 0s infinite;
	-o-animation: CallUp 0.5s ease 0s infinite;
  }
  .HeaderMobile .box .button .word a img {
	position: relative;
  }
  .HeaderMobile .box .button .call a {
	color: #000;
	font-size: 16px;
  }
  .HeaderMobile .box .button .Lanuage {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	    -ms-flex-align: center;
	        align-items: center;
	height: 100%;
	margin-left: 50px;
  }
  .HeaderMobile .box .button .Lanuage p {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	    -ms-flex-pack: distribute;
	        justify-content: space-around;
	border: 1px solid #999;
	padding: 7px 8px;
	border-radius: 15px;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
  }
  .HeaderMobile .box .button .Lanuage p a {
	color: #999;
	font-size: 14px;
	display: inline-block;
	line-height: 16px;
	text-decoration: none;
	padding: 0 7px;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
  }
  .HeaderMobile .box .button .Lanuage p a:not(:last-child) {
	border-right: 1px solid #999;
  }
  .HeaderMobile .box .button .Lanuage p a:hover {
	color: #008022;
  }
  .HeaderMobile .box .button .search{
	/* margin-right: .5rem; */
  }
  .HeaderMobile .box .button .menu {
	margin-left: .5rem;
  }
  .HeaderMobile .box .button .menu .menu_btn p {
	position: relative;
  }
  .HeaderMobile .box .button .menu .menu_btn p span {
	display: block;
	width: 30px;
	height: 2px;
	background: #008022;
	margin: 8px 0;
  }
  .HeaderMobile .box .button .menu .menu_btn p span:nth-child(1) {
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
  }
  .HeaderMobile .box .button .menu .menu_btn p span:nth-child(2) {
	opacity: 0;
  }
  .HeaderMobile .box .button .menu .menu_btn p span:nth-child(3) {
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
  }
  .HeaderMobile .box .button .menu .menu_btn p::before {
	content: '';
	position: absolute;
	top: 0;
	width: 100%;
	height: 2px;
	background: #008022;
	top: 50%;
	margin-top: -1px;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
  }
  .HeaderMobile .box .button .menu .menu_btn p::after {
	content: '';
	position: absolute;
	top: 0;
	width: 100%;
	height: 2px;
	background: #008022;
	top: 50%;
	margin-top: -1px;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
  }
  .HeaderMobile .box .button .menu .menu_btn.active p span {
	opacity: 0;
  }
  .HeaderMobile .box .button .menu .menu_btn.active p::before {
	-webkit-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
  }
  .HeaderMobile .box .button .menu .menu_btn.active p::after {
	-webkit-transform: rotate(-45deg);
	    -ms-transform: rotate(-45deg);
	        transform: rotate(-45deg);
  }
  .MobileNav {
	position: absolute;
	width: 100%;
	height: -webkit-calc(100vh - 58px);
	height: calc(100vh - 58px);
	background: #fff;
	z-index: 3;
	display: none;
  }
  .MobileNav .box {
	margin: 0 5vw;
	padding: 30px 0;
	height: 100%;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
  }
  .MobileNav .box .box_scroll {
	overflow: scroll;
	height: 100%;
	-ms-scroll-chaining: none;
	    overscroll-behavior: contain;
  }
  .MobileNav .box .box_scroll::-webkit-scrollbar {
	display: none;
  }
  .MobileNav .box .item > p {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	    -ms-flex-align: center;
	        align-items: center;
	position: relative;
	/* pointer-events: none; */
  }
  .MobileNav .box .active p {
	pointer-events: auto;
  }
  .MobileNav .box .item > p a {
	display: block;
	font-size: 22px;
	line-height: 76px;
	color: #000;
	text-decoration: none;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
  }
  .MobileNav .box .item > p span {
	width: 22px;
	height: 76px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	    -ms-flex-align: center;
	        align-items: center;
	position: absolute;
	z-index: 2;
	right: 0;
  }
  .MobileNav .box .item > p i {
	display: block;
	width: 22px;
	height: 1px;
	background: #999;
	position: relative;
  }
  .MobileNav .box .item > p i::after {
	position: absolute;
	top: 0;
	left: 0;
	content: '';
	width: 100%;
	height: 100%;
	background: #999;
	-webkit-transform: rotate(90deg);
	    -ms-transform: rotate(90deg);
	        transform: rotate(90deg);
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
  }
  .MobileNav .box .item > p .active i::after {
	-webkit-transform: rotate(180deg);
	    -ms-transform: rotate(180deg);
	        transform: rotate(180deg);
  }
  .MobileNav .box .item ul {
	display: none;
	padding: 16px 24px;
	list-style: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .MobileNav .box .item:last-child p {
	/* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
  }
  .MobileNav .box .item ul li a {
	display: block;
	font-size: 20px;
	color: #000;
	line-height: 54px;
	opacity: 0.8;
	text-decoration: none;
  }

  
.search_container {
	position: fixed;
	left: 0;
	width: 100%;
	padding: 0.9rem 0;
	top: 1rem;
	background: #000;
	display: none;
	z-index: 2;
	box-shadow: 0px 2px 10px #f6f6f6;
}

.search_container .layui-icon-close {
	position: absolute;
	top: 0.3rem;
	right: 12%;
	font-size: 0.26rem;
	color: #fff;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
	cursor: pointer;
}

.search_container .layui-icon-close:hover {
	color: #073190;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.search_container .box_search {
	width: min(6rem, 80vw);
	margin: auto;
	position: relative;
}

.search_container .box_search input[type='text'] {
	display: block;
 width: 100%;
	height: 0.64rem;
	border: 0;
	background: #f2f2f2;
	font-size: 16px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0 0.3rem;
}

.search_container .box_search label {
	display: block;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 0.3rem;
	cursor: pointer;
}

.search_container .box_search label input {
	display: none;
}

.search_container .box_search label p svg {
	vertical-align: bottom;
}

.search_container .box_search label p svg path {
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
}

.search_container .box_search label:hover p svg path {
	fill: #073190;
}
@media screen and (max-width:992px) {
	.lpk_pc {
		display: none;
	}

	.lpk_phone {
		display: block;
	}
	.MobileMain{
		display: block;
	}
}
@media screen and (max-width: 699px) {
	.MobileNav .box .item > p a {
		font-size: 16px;
		line-height: 50px;
	  }
	  .MobileNav .box .item > p span {
		width: 15px;
		height: 50px;
	  }
	  .MobileNav .box .item > p font {
		display: inline-block;
		-webkit-transform: scale(0.8);
		    -ms-transform: scale(0.8);
		        transform: scale(0.8);
		position: relative;
		right: -2px;
	  }
	  .MobileNav .box .item ul li a {
		font-size: 14px;
		line-height: 36px;
	  }
	  .MobileNav .box .item ul {
		padding: 16px 20px;
	  }
}


