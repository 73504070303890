.w1280 {
  width: min(12.8rem, 90vw);
  box-sizing: border-box;
  margin: auto;
}
html,
body {
  user-select: none;
}
body::-webkit-scrollbar {
  display: none;
} 
.header_container {
  position: sticky;
  top: 0;
  width: 100%;
  /* background: #000; */
  background: linear-gradient(to top, #2a668a ,#000000 );
  z-index: 7;
}
.header_container .box .logo {
  display: flex;
  align-items: center;
}
.header_container .box .logo a {
  display: block;
      color: #d5d3e3;
      font-size: 24px;
}
.header_container .box .logo a img {
  height: 36px;
}
.header_container .box .nav {
  display: flex;
}
.header_container .box .nav .item {
  padding: 0 30px;
  position: relative;
}
.header_container .box .nav .item p {
  position: relative;
}
.header_container .box .nav .item p a {
  display: block;
  line-height: 0.8rem;
  font-size: 0.18rem;
  color: #fff;
}
.header_container .box .nav .item p::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transition: 0.4s ease;
  width: 0%;
  height: 0.05rem;
  transform: translateX(-50%);
  background: #008022;
}
.header_container .box .nav .item div {
  position: absolute;
  width: 1.35rem;
  left: 50%;
  transform: translateX(-50%);
  background: #000000;
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: 0.4s;
}
.header_container .box .nav .item div ul {
  min-height: 0;
}
.header_container .box .nav .item div ul li a {
  font-size: 15px;
  color: #acacac;
  display: block;
  text-align: center;
  line-height: 0.4rem;
  transition: 0.4s;
}
.header_container .box .nav .item div ul li a:hover {
  color: #008022;
}
.header_container .box .nav .item:hover p::after {
  width: 1.35rem;
}
.header_container .box .nav .item:hover div {
  grid-template-rows: 1fr;
}
.header_container .box .nav .active p::after {
  width: 1.35rem;
}
.header_container .box .search {
  display: flex;
  align-items: center;
}
.header_container .box .search form {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.header_container .box .search input[type='text'] {
  display: block;
  width: 170px;
  height: 30px;
  box-sizing: border-box;
  border: 0;
  background: #fff;
  font-size: 16px;
  padding: 0 40px 0 10px;
}
.header_container .box .search p {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: #008022;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header_container .box .search p img {
  cursor: pointer;
}
.header_container .box .search p input {
  display: none;
}
.header_container .box .search p label {
  margin: auto;
}
.home_banner_container .banner .swiper-container .swiper-slide {
  position: relative;
}
.home_banner_container .banner .swiper-container .swiper-slide .img img {
  display: block;
  width: 100%;
  background: linear-gradient(to top, #030624 ,#2a678b )
}
.home_banner_container .banner .swiper-container .swiper-slide .img{
  width: 100%;
  height: 625px;
  background: linear-gradient(to top, #030624 ,#2a678b )
}
@media screen and (max-width: 1200px){
  .home_banner_container .banner .swiper-container .swiper-slide .img{
    width: 100%;
    height: 260px;
    background: linear-gradient(to top, #030624 ,#2a678b )
  }
}

.home_banner_container .banner .swiper-container .swiper-slide .position {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.home_banner_container .banner .swiper-container .swiper-slide .position .main {
  padding: 0 0.5rem;
  color: #fff;
}
.home_banner_container .banner .swiper-container .swiper-slide .position .main .enTitle {
  font-family: 'HelveticaNowText-Light';
  font-size: 0.22rem;
}
.home_banner_container .banner .swiper-container .swiper-slide .position .main .cnTitle {
  font-family: 'Alimama_ShuHeiTi_Bold';
  font-size: 0.56rem;
  letter-spacing: 0.2rem;
  margin-top: 0.32rem;
}
.home_banner_container .banner .swiper-container .swiper-slide .position .main .link {
  margin-top: 0.45rem;
}
.home_banner_container .banner .swiper-container .swiper-slide .position .main .link a {
  font-size: 0.18rem;
  color: #fff;
  display: inline-block;
  padding: 0 0.2rem;
  line-height: 0.32rem;
  border-radius: 6px;
  background: linear-gradient(to right, #008029, #00697e);
}
.p72 {
  padding-top: 0.72rem;
}
.p72_ {
  padding-bottom: 0.72rem;
}
.p104_ {
  padding-bottom: 1.04rem;
}
.title_container {
  color: #fff;
  text-align: center;
}
.title_container h5 {
  font-size: 0.36rem;
  font-weight: bold;
}
.title_container h6 {
  font-family: 'HelveticaNowText-Light';
  font-size: 0.22rem;
  margin-top: 0.12rem;
}
.title_container.color h5,
.title_container.color h6 {
  color: #132e61;
}
.home_ServiceProvider_container {
  min-height: 1rem;
  /* background: url(https://jsm-1305290939.cos.ap-chengdu.myqcloud.com/images/bg1.png) no-repeat 50% 50%; */
  background-color: #030624;
  background-size: cover;
}
.home_ServiceProvider_container .box .desc {
  font-size: 16px;
  text-align: center ;
  padding: 0 1.28rem;
  margin-top: 0.18rem;
  color: #fff;
  line-height: 20px;
}
.home_ServiceProvider_container .box .banner {
  width: 420px;
  margin: 45px auto 0;
  position: relative;
}
.home_ServiceProvider_container .box .banner .img_ img {
  width: 100%;
}
.home_ServiceProvider_container .box .banner .swiper-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 233px;
}
.home_ServiceProvider_container .box .banner .swiper-container .swiper-slide {
  overflow: hidden;
  position: relative;
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  border-radius: 6px;
}
.home_ServiceProvider_container .box .banner .swiper-container .swiper-slide .position {
  width: 100%;
  height: calc(100% - 5px);
  display: flex;
  align-items: center;
  transition: 0.4s;
}
.home_ServiceProvider_container .box .banner .swiper-container .swiper-slide .position > div {
  display: flex;
}
.home_ServiceProvider_container .box .banner .swiper-container .swiper-slide .position .left {
  padding-left: 24px;
  display: flex;
  align-items: center;
}
.home_ServiceProvider_container .box .banner .swiper-container .swiper-slide .position .left img {
  width: 46px;
}
.home_ServiceProvider_container .box .banner .swiper-container .swiper-slide .position .right {
  margin-left: 14px;
}
.home_ServiceProvider_container .box .banner .swiper-container .swiper-slide .position .right h5 {
  font-size: 0.26rem;
  font-weight: bold;
  color: #fff;
  margin-top: -2px;
}
.home_ServiceProvider_container .box .banner .swiper-container .swiper-slide .position .right p {
  font-family: 'HelveticaNowText-Light';
  color: #fff;
  font-size: 0.22rem;
}
.home_ServiceProvider_container .box .banner .swiper-container .swiper-slide.active {
  z-index: 10 !important;
}
.home_ServiceProvider_container .box .banner .swiper-container .swiper-slide.active .position {
  opacity: 1;
}
.home_ServiceProvider_container .box .banner .swiper-container .swiper-slide-active {
  transition: 0.4s;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}
.home_ServiceProvider_container .box .banner .button div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
}
.home_ServiceProvider_container .box .banner .button div i {
  color: #fff;
  font-size: 0.3rem;
  font-weight: 100;
}
.home_ServiceProvider_container .box .banner .button .prev {
  left: -0.85rem;
}
.home_ServiceProvider_container .box .banner .button .next {
  right: -0.85rem;
}
.home_ServiceProvider_container .box .page {
  text-align: center;
  margin-top: 0.3rem;
}
.home_ServiceProvider_container .box .page div span {
  width: 6px;
  height: 6px;
  border: 1px solid #fff;
  opacity: 1;
  background: none;
  transition: 0.4s;
  margin: 0 3px;
}
.home_ServiceProvider_container .box .page div .swiper-pagination-bullet-active {
  background: #fff;
}



.home_ServiceProvider_container .box .banner1 {
  width: 520px;
  margin: 45px auto 0;
  position: relative;
}
.home_ServiceProvider_container .box .banner1 .swiper-slide {
  width: 260px;
  position: relative;
}
.home_ServiceProvider_container .box .banner1 .swiper-slide img {
  width: 100%;
}
.home_ServiceProvider_container .box .banner1 .swiper-slide .position {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);
  display: flex;
  align-items: center;
  transition: 0.4s;
}
.home_ServiceProvider_container .box .banner1 .swiper-slide .position > div {
  display: flex;
}
.home_ServiceProvider_container .box .banner1 .swiper-slide .position .left {
  padding-left: 24px;
  display: flex;
  align-items: center;
}
.home_ServiceProvider_container .box .banner1 .swiper-slide .position .left img {
  width: 46px;
}
.home_ServiceProvider_container .box .banner1 .swiper-slide .position .right {
  margin-left: 14px;
}
.home_ServiceProvider_container .box .banner1 .swiper-slide .position .right h5 {
  font-size: 0.26rem;
  font-weight: bold;
  color: #fff;
  margin-top: -2px;
}
.home_ServiceProvider_container .box .banner1 .swiper-slide .position .right p {
  font-family: 'HelveticaNowText-Light';
  color: #fff;
  font-size: 0.22rem;
}
.home_ServiceProvider_container .box .banner1 .button div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
}
.home_ServiceProvider_container .box .banner1 .button div i {
  color: #fff;
  font-size: 0.3rem;
  font-weight: 100;
}
.home_ServiceProvider_container .box .banner1 .button .prev {
  left: -0.85rem;
}
.home_ServiceProvider_container .box .banner1 .button .next {
  right: -0.85rem;
}




.home_product {
  background: #007163;
  background: linear-gradient(to top, #132f61 ,#030624 )
  /* linear-gradient(to right, #00725d ,#006980 ); */
}
.home_product .box {
  margin-top: 0.52rem;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
}
.home_product .box .item a {
  display: block;
  border-radius: 6px;
  overflow: hidden;
}
.home_product .box .item a .img {
  overflow: hidden;
}
.home_product .box .item a .img img {
  width: 100%;
  transition: all 0.4s;
    height: 265px;
}
.home_product .box .item a .info .left {
  width: 0.96rem;
  height: 0.96rem;
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #0182ab, #2d61b7);
}
.home_product .box .item a .info .left img {
  margin: auto;
  max-width: 80%;
  max-height: 80%;
}
.home_product .box .item a .info .right {
  background: #fff;
  width: calc(100% - 0.96rem);
  box-sizing: border-box;
  padding: 0 0.25rem;
  display: flex;
  align-items: center;
}
.home_product .box .item a .info .right h5 {
  font-size: 0.2rem;
  font-weight: bold;
  color: #000;
  transition: 0.4s;
  display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
	text-justify: distribute-all-lines;
}
.home_product .box .item a .info .right p {
  font-size: 12px;
  color: #787878;
  margin-top: 4px;
  
  display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-justify: distribute-all-lines;
}
.home_product .box .item a:hover .img img {
  transform: scale(1.1);
}
.home_product .box .item a:hover .info .right h5 {
  color: #008022;
}
.home_technical {
  background: linear-gradient(to top, #aac1d8 ,#132f61 )
  /* #132e61; */
}
.home_technical .box {
  margin-top: 0.52rem;
  display: grid;
  grid-column-gap: 0.54rem;
  grid-row-gap: 0.32rem;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 40px;
}
.home_technical .box .item {
  background: #fff;
  border-radius: 0.58rem;
  overflow: hidden;
  display: flex;
}
.home_technical .box .item .left {
  width: 1.16rem;
  height: 1.16rem;
  border-radius: 0.58rem;
  background: linear-gradient(to right, #0182ab, #2d61b7);
  display: flex;
  align-items: center;
}
.home_technical .box .item .left img {
  margin: auto;
  max-width: 54%;
  max-height: 54%;
}
.home_technical .box .item .right {
  width: calc(100% - 1.16rem);
  box-sizing: border-box;
  padding: 0 0.36rem;
  display: flex;
  align-items: center;
}
.home_technical .box .item .right h5 {
  font-size: 0.2rem;
  font-weight: bold;
}
.home_technical .box .item .right p {
  font-size: 12px;
  color: #787878;
  line-height: 20px;
  margin-top: 5px;
}
.home_news_container {
  /* background: url(https://jsm-1305290939.cos.ap-chengdu.myqcloud.com/images/bg2.png) no-repeat 50% 50%; */
  background: linear-gradient(to top, #d1d1d1 ,#a9c0d7 );
  background-size: cover;
  padding-bottom: 0.54rem;
}
.home_news_container .box {
  margin-top: 0.52rem;
  display: flex;
}
.home_news_container .box .left {
  width: 4.3rem;
}
.home_news_container .box .left a {
  display: block;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}
.home_news_container .box .left a .img {
  overflow: hidden;
}
.home_news_container .box .left a .img img {
  width: 100%;
  height: 2.7rem;
  overflow: hidden;
  transition: 0.4s;
  object-fit: cover;
}
.home_news_container .box .left a .info {
  display: flex;
}
.home_news_container .box .left a .info ._le {
  width: 0.96rem;
  height: 0.96rem;
  background: linear-gradient(to right, #0182ab, #2d61b7);
  display: flex;
  align-items: center;
}
.home_news_container .box .left a .info ._le > div {
  margin: auto;
  text-align: center;
}
.home_news_container .box .left a .info ._le > div h5 {
  font-family: 'Arial_Bold';
  font-size: 0.54rem;
  color: #fff;
  line-height: 1;
  margin-bottom: 3px;
}
.home_news_container .box .left a .info ._le > div p {
  font-family: 'Arial';
  font-size: 15px;
  color: #fff;
}
.home_news_container .box .left a .info ._ri {
  background: #fff;
  padding: 0 0.26rem;
  width: calc(100% - 0.96rem);
  box-sizing: border-box;
}
.home_news_container .box .left a .info ._ri h5 {
  font-size: 0.2rem;
  font-weight: bold;
  color: #000;
  margin-top: 0.14rem;
}
.home_news_container .box .left a .info ._ri p {
  font-size: 12px;
  color: #787878;
  line-height: 20px;
  font-weight: lighter;
  margin-top: 5px;
}
.home_news_container .box .left a:hover .img img {
  transform: scale(1.1);
}
.home_news_container .box .right {
  width: calc(100% - 4.3rem);
  display: flex;
}
.home_news_container .box .right .item {
  box-sizing: border-box;
  width: 33.333%;
  padding-left: 10px;
}
.home_news_container .box .right .item a {
  height: 100%;
  display: block;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 0.25rem;
  transition: 0.4s;
  position: relative;
}
.home_news_container .box .right .item a .content0 {
  font-family: 'Arial_Bold';
  font-size: 0.54rem;
  color: #008022;
  padding-top: 0.24rem;
  transition: 0.4s;
}
.home_news_container .box .right .item a .content1 {
  font-family: 'Arial';
  font-size: 15px;
  transition: 0.4s;
}
.home_news_container .box .right .item a .content2 {
  font-size: 0.2rem;
  font-weight: bold;
  line-height: 0.3rem;
  height: 0.6rem;
  color: #000;
  margin-top: 0.32rem;
  transition: 0.4s;
}
.home_news_container .box .right .item a .content3 {
  font-size: 12px;
  color: #787878;
  font-weight: lighter;
  line-height: 20px;
  height: 80px;
  margin-top: 12px;
  transition: 0.4s;
}
.home_news_container .box .right .item a .content4 {
  margin-top: 0.2rem;
}
.home_news_container .box .right .item a .content4 path {
  transition: 0.4s;
}
.home_news_container .box .right .item a .content0,
.home_news_container .box .right .item a .content1,
.home_news_container .box .right .item a .content2,
.home_news_container .box .right .item a .content3,
.home_news_container .box .right .item a .content4 {
  position: relative;
  z-index: 1;
}
.home_news_container .box .right .item a::after {
  content: '';
  position: absolute;
  transition: 0.4s;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: linear-gradient(to bottom, #0182ab, #2d61b7);
}

@media screen and (min-width: 1200px){
    .home_news_container .box .right .item a:hover .content0,
    .home_news_container .box .right .item a:hover .content1,
    .home_news_container .box .right .item a:hover .content2,
    .home_news_container .box .right .item a:hover .content3 {
      color: #fff;
    }
    .home_news_container .box .right .item a:hover .content4 path {
      fill: #fff;
    }
    .home_news_container .box .right .item a:hover::after {
      opacity: 1;
    }


}





.home_news_container .box1 {
  margin-top: 0.3rem;
}
.index_link a {
  font-size: 12px;
  color: #132e61;
  line-height: 25px;
  width: 76px;
  text-align: center;
  border: 1px solid #132e61;
  border-radius: 0.3rem;
  display: block;
  margin: auto;
  transition: 0.4s;
}
.index_link a svg {
  vertical-align: middle;
}
.index_link a svg path {
  transition: 0.4s;
}
.index_link a:hover {
  background: #132e61;
  color: #fff;
}
.index_link a:hover svg path {
  fill: #fff;
}
.home_cueromer_container {
  background: linear-gradient(to top, #001632 ,#d0d1d1 )
  /* #d1d1d1; */
}
.home_cueromer_container .box {
  width: 1189px;
  margin: 0.46rem auto 0;
  position: relative;
  height: 5rem;
  overflow: hidden;
  box-shadow: 54px 39px 1rem rgba(0,0,0,.2);
  border-radius: 56px 56px 0 0;
}
.home_cueromer_container .box .img img {
  width: 100%;
}
.home_cueromer_container .box .position {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.home_cueromer_container .box .position .main {
  padding: 1rem 1rem 0;
}
.home_cueromer_container .box .position .main .list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 4px;
}
.home_cueromer_container .box .position .main .list .item {
  height: 1.44rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 2px solid #e5e5e5;
}
.home_cueromer_container .box .position .main .list .item img {
  margin: auto;
  max-width: 80%;
  max-height: 80%;
  transition: 0.4s;
}
.home_cueromer_container .box .position .main .list .item:hover img {
  transform: scale(1.1);
}
.home_cueromer_container .box .position .box1 {
  margin-top: 0.34rem;
}
.footer_container {
  background: #001632;
}
.footer_container .box {
  padding: 0.52rem 0 0.28rem;
}
.footer_container .box .boxLeft .content0 img {
  height: 36px;
}
.footer_container .box .boxLeft .content1 {
  margin-top: 0.38rem;
}
.footer_container .box .boxLeft .content1 p {
  font-size: 12px;
  color: #fff;
  line-height: 26px;
}
.footer_container .box .boxLeft .content1 p a{
    color: #fff;
}
.footer_container .box .boxLeft .content2 {
  margin-top: 0.2rem;
}
.footer_container .box .boxLeft .content2 img {
  width: 1.2rem;
}
.footer_container .box .boxRight {
  display: flex;
}
.footer_container .box .boxRight .item {
  margin-left: 0.48rem;
}
.footer_container .box .boxRight .item h5 a {
  display: block;
  font-size: 0.18rem;
  color: #fff;
  height: 25px;
}
.footer_container .box .boxRight .item ul {
  margin-top: 0.16rem;
}
.footer_container .box .boxRight .item ul li a {
  font-size: 12px;
  color: #fff;
  font-weight: lighter;
  display: block;
  line-height: 0.3rem;
  transition: 0.4s;
}
.footer_container .box .boxRight .item ul li a:hover {
  color: #fff;
}
.footer_container .box1 {
  /* border-top: 1px solid #888888; */
  font-size: 12px;
  font-weight: lighter;
  color: #999;
  text-align: center;
  padding: 0.25rem 0;
}
.footer_container .box1 a {
  color: #999;
  display: inline-block;
  margin: 0 5px;
}
.innerBanner {
  position: relative;
}
.innerBanner .img {
  width: 100%;
  height: 520px;
  background: linear-gradient(to top, #030624 ,#2a678b );
}
@media screen and (max-width: 1200px){
  .innerBanner .img{
    width: 100%;
    height: 220px;
  }
}

.innerBanner .position {
  position: absolute;
  /*left: 6rem;*/
  top: 1.3rem;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  
}

.innerBanner .position .w1280{
     padding: 0 0.5rem;
}



.innerBanner .position h6 {
  font-family: 'HelveticaNowText-Light';
  font-size: 0.22rem;
  color: #fff;
  margin-bottom: 0.22rem;
}
.innerBanner .position h5 {
  font-family: 'Alimama_ShuHeiTi_Bold';
  font-size: 0.56rem;
  color: #fff;
  letter-spacing: 0.25rem;
}
.innerBanner .position .desc {
  font-size: 0.16rem;
  font-weight: lighter;
  color: #fff;
  margin-top: 0.22rem;
}
.innerBanner .position .link {
  margin-top: 0.3rem;
}
.innerBanner .position .link a {
  font-size: 0.18rem;
  color: #fff;
  display: inline-block;
  padding: 0 0.2rem;
  line-height: 0.32rem;
  border-radius: 6px;
  cursor: default;
  background: linear-gradient(to right, #008029, #00697e);
}
.innerBanner .l416 {
  /*left: 4.16rem;*/
}
.select_nav_container {
  background: #030624;
}
.select_nav_container .box {
  display: flex;
  flex-wrap: wrap;
  background: #030624;
  overflow: hidden;
}
.select_nav_container .box .item {
  width: calc(25%);
  border-radius: 10%;
  border-bottom: 2px solid #bed0e0;
  position: relative;
  margin:32px;
  box-sizing: border-box;
}

/* .select_nav_container .box .item::before {
  content: '';
  position: absolute;
  top: 0;
  right: -2px;
  width: 2px;
  height: 100%;
  background: #bed0e0;
} */
.select_nav_container .box .item::after {
  position: absolute;
  bottom: -2px;
  left: 0px;
  height: 2px;
  width: 100%;
  background: #bed0e0;
}
.select_nav_container .box .item a {
  display: block;
  font-size: 0.2rem;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  line-height: 1.18rem;
}
.select_nav_container .box .item a img {
  margin-right: 15px;
  width: 0.4rem;
}
.select_nav_container .box .item a .img2 {
  display: none;
}
.select_nav_container .box .item a span {
  display: inline-block;
  width: 1.3rem;
  text-align: left;
}
.select_nav_container .box .item:hover {
  background: linear-gradient(to right, #0182ab, #2d61b7);
}
.select_nav_container .box .item:hover a {
  color: #fff;
}
.select_nav_container .box .item:hover a .img1 {
  display: none;
}
.select_nav_container .box .item:hover a .img2 {
  display: inline-block;
}
.select_nav_container .box .active {
  background: linear-gradient(to right, #0182ab, #2d61b7);
}
.select_nav_container .box .active a {
  color: #fff;
}
.select_nav_container .box .active a .img1 {
  display: none;
}
.select_nav_container .box .active a .img2 {
  display: inline-block;
}
.select_nav_container.select_nav_container_center .box {
  justify-content: center;
}
.select_nav_container.select_nav_container_center .box .item:first-child {
  /* border-left: 2px solid #bed0e0; */
}
.select_nav_container.select_nav_container_5 .box .item {
  width: 20%;
}
.about_contaiiner {
  background: linear-gradient(to top, #2c60b5, #030624);
}
.about_contaiiner .box .left {
  width: 43%;
}
.about_contaiiner .box .left p img {
  width: 100%;
}
.about_contaiiner .box .right {
  width: 46%;
  padding-top: 16px;
}
.about_contaiiner .box .right .content0 {
  font-size: 0.23rem;
  font-weight: bold;
  color: #fff;
}
.about_contaiiner .box .right .content1 {
  font-size: 12px;
  font-family: 'HelveticaNowText-Light';
  color: #505050;
  transform: scale(0.9);
  transform-origin: left center;
}
.about_contaiiner .box .right .content2 {
  margin-top: 0.26rem;
}





.about_contaiiner .box .right .content2 p {
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  color:#e6e6e6
}
.about_contaiiner .box1 .title {
  text-align: center;
}
.about_contaiiner .box1 .title p {
  font-size: 0.23rem;
  display: inline-block;
  line-height: 0.7rem;
  color: #fff;
  padding: 0 0.78rem;
  border-radius: 6px;
  background: linear-gradient(to right, #0182ab, #2d61b7);
}
.about_contaiiner .box1 .list {
  margin: 0.32rem 0 0.56rem;
  display: flex;
  gap: 14px;
}
.about_contaiiner .box1 .list .item {
  background: #fff;
  flex: 1;
  min-height: 0.5rem;
  border-radius: 0.16rem;
  text-align: center;
  padding: 0.3rem 0;
  transition: .4s;
}
.about_contaiiner .box1 .list .item:hover{
    box-shadow: 0px 0px 12px rgba(0,0,0,0.2);
}
.about_contaiiner .box1 .list .item .content0 img {
  width: 1.1rem;
}
.about_contaiiner .box1 .list .item .content1 {
  margin-top: 0.22rem;
  font-size: 0.2rem;
  font-weight: bold;
}
.about_contaiiner .box1 .list .item .content1 span {
  font-size: 0.48rem;
  font-family: 'Arial_Bold';
  position: relative;
  top: 5px;
}
.about_contaiiner .box1 .list .item .content1 font {
  font-size: 0.35rem;
}
.about_contaiiner .box1 .list .item .content1 text {
  font-size: 0.32rem;
  font-family: 'Arial_Bold';
}
.about_contaiiner .box1 .list .item .content2 {
  font-size: 14px;
  color: #505050;
  margin-top: 5px;
}
.about_contaiiner .box2 {
  flex-flow: row-reverse;
}
.fzlc_container {
  background: linear-gradient(to top, #001632, #2d61b7);
}
.fzlc_container .box {
  margin-top: 0.68rem;
}
.fzlc_container .box .list {
  position: relative;
  padding-bottom: 1.3rem;
}
.fzlc_container .box .list::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  width: 2px;
  background-image: linear-gradient(to bottom, #fff 0%, #fff 50%, transparent 50%);
  background-size: 2px 10px;
}
.fzlc_container .box .list .item {
  display: flex;
}
.fzlc_container .box .list .item .ite {
  width: 45%;
  display: flex;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 200px) {
  .fzlc_container .box .list .item .ite::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(100%);
    width: 0.64rem;
    height: 2px;
    z-index: 1;
    background-image: linear-gradient(to right, #fff 0%, #fff 50%, transparent 50%);
    background-size: 10px 2px;
  }
  .fzlc_container .box .list .item .ite::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -0.68rem;
    width: 0.08rem;
    height: 0.08rem;
    background: #fff;
    border-radius: 50%;
    z-index: 2;
  }
}
.fzlc_container .box .list .item .ite .le {
  width: 2.26rem;
  overflow: hidden;
  border-radius: 12px 0 0 12px;
}
.fzlc_container .box .list .item .ite .le img {
  width: 100%;
  transition: 0.4s;
  height: 100%;
}
.fzlc_container .box .list .item .ite .ri {
  flex: 1;
  padding: 0.24rem 0.34rem;
   background: #fff;
}
.fzlc_container .box .list .item .ite .ri .content0 .title {
  font-size: 0.18rem;
  font-weight: bold;
}
.fzlc_container .box .list .item .ite .ri .content0 .title span {
  font-size: 0.36rem;
  font-family: 'Arial_Bold';
  color: #008022;
  position: relative;
  top: 0.03rem;
}
.fzlc_container .box .list .item .ite .ri .content0 .desc {
  margin-top: 0.12rem;
  font-size: 14px;
}
.fzlc_container .box .list .item .ite:hover .le img {
  transform: scale(1.1);
}
@media screen and (min-width: 1200px) {
       
  .fzlc_container .box .list .item:nth-child(2n) {
    justify-content: flex-end;
  }
  .fzlc_container .box .list .item:nth-child(2n) .ite::after {
    right: auto;
    left: 0;
    transform: translateY(-50%) translateX(-100%);
  }
  .fzlc_container .box .list .item:nth-child(2n) .ite::before {
    right: auto;
    left: -0.67rem;
  }
}
.product_container {
  /* background: url(https://jsm-1305290939.cos.ap-chengdu.myqcloud.com/images/img12.png) no-repeat left top; */
  background-size: cover;
  padding-top: 52px;
  background: linear-gradient(to top, #001632, #030624);
}
.product_container .box {
  /* background: rgba(255, 255, 255, 0.6); */
  padding: 26px 26px 0.72rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.2rem;
}
.product_container .box .item{
  border-radius: 20px;
}
.product_container .box .item a {
  display: block;
}
.product_container .box .item a .img {
  position: relative;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}
.product_container .box .item a .img > img {
  width: 100%;
  transition: 0.4s;
}

.product_container .box .item a .img p {
  position: absolute;
  top: 0;
  right: 0.3rem;
  background: #62a8ff;
  height: 0.72rem;
  display: flex;
  align-items: center;
  padding: 0 8px;
  width: 55px;
  box-sizing: border-box;
  display: none;
}


.product_container .box .item a .img p img {
  max-height: 0.35rem;
  transition: 0.4s;
  max-width: 100%;
  margin: auto;
}
.product_container .box .item a .main {
  background: #fff;
  padding: 0.2rem 0.28rem;
  border-radius: 0 0 20px 20px ;
}
.product_container .box .item a .main h5 {
  font-size: 0.2rem;
  font-weight: bold;
  color: #000;
  transition: 0.4s;
}
.product_container .box .item a .main .desc {
  font-size: 12px;
  color: #787878;
  font-weight: lighter;
  line-height: 20px;
  height: 40px;
  margin-top: 8px;
}
.product_container .box .item a .main .link {
  font-family: 'Alimama_ShuHeiTi_Bold';
  /*font-size: 12px;*/
  /*color: #008022;*/
  margin-top: 0.18rem;
  
  
  font-size: 12px;
    color: #132e61;
    line-height: 25px;
    width: 76px;
    text-align: center;
    border-radius: 0.3rem;
    display: block;
    transition: 0.4s;
    background: #132e61;
    color: #fff;
}



.product_container .box .item a .main .link svg {
  vertical-align: middle;
}
.product_container .box .item a .main .link svg  path{
    fill: #fff;
}
.product_container .box .item a:hover .img > img {
  transform: scale(1.1);
}
.product_container .box .item a:hover .img p img {
  transform: scale(0.9);
}
.product_container .box .item a:hover .main h5 {
  color: #008022;
}
.news_container {
  /* background: url(https://jsm-1305290939.cos.ap-chengdu.myqcloud.com/images/bg4.png) no-repeat left top; */
  background-size: cover;
  background: linear-gradient(to top, #001632, #030624);
}
.news_container .box .banner {
  position: relative;
  padding: 0.35rem;
  border-radius: 8px;
  background: #fff;
}
.news_container .box .banner a {
  box-sizing: border-box;
}
.news_container .box .banner a .left {
  width: 52%;
  overflow: hidden;
  border-radius: 8px;
}
.news_container .box .banner a .left img {
  width: 100%;
  transition: 0.4s;
}
.news_container .box .banner a .right {
  width: 44%;
  padding-top: 0.24rem;
}
.news_container .box .banner a .right .contetn0 p {
  display: inline-block;
  font-family: 'Arial';
  font-size: 0.3rem;
  color: #fff;
  background: linear-gradient(to right, #0182ab, #2d61b7);
  line-height: 0.4rem;
  padding: 0 0.28rem;
  border-radius: 0.3rem;
}
.news_container .box .banner a .right .content1 {
  font-size: 0.24rem;
  line-height: 0.4rem;
  color: #000;
  font-weight: bold;
  margin-top: 0.38rem;
}
.news_container .box .banner a .right .content2 {
  font-size: 14px;
  line-height: 26px;
  margin-top: 16px;
}
.news_container .box .banner .page {
  position: absolute;
  right: 0.35rem;
  bottom: 0.56rem;
  z-index: 1;
}
.news_container .box .banner .page div span {
  margin-left: 0.12rem;
  width: 0.22rem;
  height: 0.22rem;
  line-height: 0.22rem;
  border-radius: 50%;
  text-align: center;
  background: none;
  opacity: 1;
  border: 1px solid #333;
  font-size: 14px;
  font-family: 'Arial';
  color: #666;
  transition: 0.4s;
}
.news_container .box .banner .page div .swiper-pagination-bullet-active {
  color: #000;
  border-color: #008022;
  transform: scale(1.2);
}
.news_container .box1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 18px;
  margin-top: 28px;
  box-sizing: border-box;
}
.news_container .box1 .item {
  box-sizing: border-box;
}
.news_container .box1 .item a {
  display: block;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}
.news_container .box1 .item a .img {
  overflow: hidden;
}
.news_container .box1 .item a .img img {
  width: 100%;
  /*height: 2.7rem;*/
  overflow: hidden;
  transition: 0.4s;
}
.news_container .box1 .item a .info {
  display: flex;
  margin-top: -1px;
  width: 100%;
}
.news_container .box1 .item a .info ._le {
  width: 0.96rem;
  height: 0.96rem;
  background: linear-gradient(to right, #0182ab, #2d61b7);
  display: flex;
  align-items: center;
}
.news_container .box1 .item a .info ._le > div {
  margin: auto;
  text-align: center;
}
.news_container .box1 .item a .info ._le > div h5 {
  font-family: 'Arial_Bold';
  font-size: 0.54rem;
  color: #fff;
}
.news_container .box1 .item a .info ._le > div p {
  font-family: 'Arial';
  font-size: 15px;
  color: #fff;
}
.news_container .box1 .item a .info ._ri {
  background: #fff;
  padding: 0 0.26rem;
  flex: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.news_container .box1 .item a .info ._ri h5 {
  font-size: 0.2rem;
  font-weight: bold;
  color: #000;
  margin-top: 0.14rem;
}
.news_container .box1 .item a .info ._ri p {
  font-size: 12px;
  color: #787878;
  line-height: 20px;
  font-weight: lighter;
  /*margin-top: 5px;*/
}
.news_container .box1 .item a:hover .img img {
  transform: scale(1.1);
}
.inner_title {
  text-align: center;
}
.inner_title p {
  font-size: 0.23rem;
  display: inline-block;
  line-height: 0.7rem;
  color: #fff;
  padding: 0 0.78rem;
  border-radius: 6px;
  background: linear-gradient(to right, #0182ab, #2d61b7);
  min-width: 3.14rem;
  box-sizing: border-box;
}
.join_container {
  padding-top: 0.35rem;
  /* background: url(https://jsm-1305290939.cos.ap-chengdu.myqcloud.com/images/bg4.png) no-repeat left top; */
    background: linear-gradient(to top, #00677e, #030624);
  background-size: cover;
}
.join_container .box {
  margin-top: 20px;
  padding-bottom: 0.6rem;
  /* background: #fff; */
}
.join_container .box .list {
  padding: 0 0.38rem;
}
.join_container .box .list .item {
  border-bottom: 1px solid #000;
  position: relative;
  box-sizing: border-box;
}
.join_container .box .list .item::after {
  position: absolute;
  content: '';
  right: 0;
  bottom: 0;
  width: 0%;
  height: 1px;
  background: #008022;
  transition: 0.6s ease;
}
.join_container .box .list .item a {
  padding: 0.35rem 0;
  display: flex;
  position: relative;
}
.join_container .box .list .item a > .left {
  width: 1.88rem;
}
.join_container .box .list .item a > .left img {
  width: 100%;
}
.join_container .box .list .item a > .right {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 0.8rem 0 0.36rem;
}
.join_container .box .list .item a > .right .top {
  display: flex;
}
.join_container .box .list .item a > .right .top .left {
  font-size: 0.2rem;
  font-weight: bold;
  white-space: nowrap;
  color: #ececec;
}
.join_container .box .list .item a > .right .top .right {
  margin-left: 0.26rem;
}
.join_container .box .list .item a > .right .top .right span {
  font-size: 0.14rem;
  font-weight: lighter;
  line-height: 18px;
  display: inline-block;
  border-radius: 0.2rem;
  padding: 0 14px;
  border: 1px solid #919191;
  margin-right: 7px;
  color:#919191;
}
.join_container .box .list .item a > .right .bot {
  margin-top: 0.18rem;
  font-size: 14px;
  font-weight: lighter;
  color:#eee
}
.join_container .box .list .item a .icon {
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translateY(-50%);
}
.join_container .box .list .item a .icon img {
  width: 0.28rem;
}
.join_container .box .list .item:hover::after {
  width: 100%;
  right: auto;
  left: 0;
}
.join_container .box1 {
  margin-top: 0.6rem;
}
.join_container .box1 .list {
  margin-top: 0.52rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
.join_container .box1 .list .item {
  background: #fff;
  border-radius: 16px;
  padding: 0.3rem 0;
}
.join_container .box1 .list .item .top p {
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin: auto;
}
.join_container .box1 .list .item .top p img {
  width: 100%;
}
.join_container .box1 .list .item .bot {
  font-size: 0.24rem;
  font-weight: bold;
  text-align: center;
  margin-top: 0.16rem;
}
.jzg_container {
  background: linear-gradient(to top, #001632, #00687f);
  
}
.jzg_container .box {
  margin-top: 0.72rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1.28rem;
  grid-row-gap: 0.6rem;
  color: #fff;
}
.jzg_container .box .item {
  padding-left: 0.8rem;
  box-sizing: border-box;
  position: relative;
}
.jzg_container .box .item .content0 {
  position: absolute;
  left: 0;
  top: -5px;
}
.jzg_container .box .item .content0 img {
  width: 0.52rem;
}
.jzg_container .box .item .content1 {
  font-size: 0.24rem;
}
.jzg_container .box .item .content2 {
  font-size: 14px;
  line-height: 24px;
  margin-top: 12px;
}
.contact_container {
  /* background: url(https://jsm-1305290939.cos.ap-chengdu.myqcloud.com/images/bg4.png) no-repeat 50% 35%; */
    background: linear-gradient(to top, #01667e, #030624);
  background-size: cover;
}
.contact_container .box {
  margin-top: 0.64rem;
}
.contact_container .box .item {
  width: 32%;
  background: #fff;
  text-align: center;
  border-radius: 0.16rem;
  padding: 0.54rem 0;
}
.contact_container .box .item a {
  display: block;
}
.contact_container .box .item .content0 p {
  margin: auto;
  width: 1.46rem;
  height: 1.46rem;
}
.contact_container .box .item .content0 p img {
  width: 100%;
}
.contact_container .box .item .content1 {
  font-size: 0.24rem;
  font-weight: bold;
  margin-top: 0.34rem;
  color: #000;
}
.contact_container .box .item .content2 {
  font-size: 14px;
  color: #505050;
  margin-top: 16px;
}
.contact_container .box .item .content3 {
  text-align: center;
  margin-top: 0.32rem;
}
.contact_container .box .item .content3 p {
  display: inline-block;
  width: 45px;
  padding: 4px 0;
  border: 1px solid #008022;
  text-align: center;
  border-radius: 0.3rem;
  transition: 0.4s;
}
.contact_container .box .item .content3 p svg {
  display: block;
  margin: auto;
}
.contact_container .box .item .content3 p svg path {
  transition: 0.4s;
}
.contact_container .box .item:hover .content3 p {
  background: #008022;
}
.contact_container .box .item:hover .content3 p svg path {
  fill: #fff;
}
.contact_ewm_container {
  background: linear-gradient(to top, #001632, #00657d);
  padding: 1.14rem 0;
}
.contact_ewm_container .box .item {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}
.contact_ewm_container .box .item .ewm img {
  width: 2rem;
}
.contact_ewm_container .box .item .text {
  display: flex;
  align-items: center;
  font-size: 0.2rem;
  color: #fff;
}
.contact_ewm_container .box .item .text div {
  display: flex;
}
.contact_ewm_container .box .item .text img {
  margin-right: 12px;
  width: 0.28rem;
}
.contact_ewm_container .box .item:first-child .text {
  order: -1;
}
.contact_ewm_container .box .item:first-child .text span {
  order: -1;
}
.contact_ewm_container .box .item:first-child .text img {
  margin-right: 0;
  margin-left: 12px;
  transform: rotate(180deg);
}
.case_filter_container {
  background: #d3dfeb;
}
.case_filter_container .box {
  padding: 0.42rem 0 0.5rem;
}
.case_filter_container .box h5 {
  font-size: 0.2rem;
}
.case_filter_container .box .list {
  display: flex;
  margin-top: 0.24rem;
  /*gap: 0.14rem;*/
  flex-wrap: wrap;
}
.case_filter_container .box .list p {
  background: linear-gradient(to right, #0b8532, #0c7086);
  padding: 2px;
  border-radius: 0.3rem;
  margin-right: 0.14rem;
}
.case_filter_container .box .list p.active a{
    background: transparent;
   color: #fff;
    
}
.case_filter_container .box .list p a {
  font-size: 0.2rem;
  display: block;
  line-height: 0.34rem;
  padding: 0 0.25rem;
  color: #000;
  background: #fff;
  border-radius: 0.3rem;
  min-width: 1.6rem;
  box-sizing: border-box;
  text-align: center;
  transition: 0.4s;
  
}
.case_filter_container .box .list p:hover a {
  background: transparent;
  color: #fff;
}
.case_container {
  /* background: url(https://jsm-1305290939.cos.ap-chengdu.myqcloud.com/images/bg6.png) no-repeat 50% 35%; */
  background-size: cover;
  background: linear-gradient(to top, #001632, #030624);
}
.case_container .box {
  padding: 1.2rem 0 1.6rem;
}
.case_container .box .list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 15px;
}
.case_container .box .list .item {
  height: 2.25rem;
  width: 2rem;
  clip-path: ellipse(57% 49% at 50% 50%);
}
.case_container .box .list .item a {
  width: 100%;
  height: 100%;
  display: block;
  background: #fff;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  position: relative;
}
.case_container .box .list .item a .img {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.case_container .box .list .item a .img img {
  margin: auto;
  max-width: 60%;
  max-height: 60%;
}
.case_container .box .list .item a .position {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center	;
  opacity: 0;
}
.case_container .box .list .item a .position p {
  font-size: 0.2rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin: auto;
  padding: 0 0.2rem;
}
.case_container .box .list .item:hover a {
  background: #132e61;
}
.case_container .box .list .item:hover a .img {
  opacity: 0;
}
.case_container .box .list .item:hover a .position {
  opacity: 1;
}
.case_container .box .list:nth-child(2n) {
  width: calc(100% - 2rem - 15px);
  margin: auto;
}
.case_container .box .list:not(:first-child) {
  margin-top: -40px;
}
.case_container .phone {
  display: none;
}
.product_info_container {
  background: linear-gradient(to top, #001632, #030624);
  background-size: cover;
  padding-top: 0.6rem;
}
.product_info_container .box {
  /* background: #fff; */
  padding: 0 0.7rem 0.7rem;
}
.product_info_container .box .content0 .left {
  width: 116px;
  height: 1.3rem;
  background: linear-gradient(to right, #0182ab, #2d61b7);
  border-radius: 0 0 8px 8px;
  display: flex;
  align-items: center;
}
.product_info_container .box .content0 .left p {
  margin: auto;
}
.product_info_container .box .content0 .left p img {
  max-width: 0.76rem;
}
.product_info_container .box .content0 .right {
  width: 83%;
}

.product_info_container .box .content0 .right .main {
  margin-top: 0.48rem;
  border-bottom: 2px solid #008022;
  padding-bottom: 0.11rem;
}
.product_info_container .box .content0 .right .main h5 {
  font-size: 0.24rem;
  font-weight: bold;
  color: ghostwhite;
}
.product_info_container .box .content0 .right .main .ite {
  margin-top: 0.16rem;
}
.product_info_container .box .content0 .right .main .ite .le {
  color: #939393;
}
.product_info_container .box .content0 .right .main .ite .le a {
  font-size: 14px;
  color: #939393;
  font-weight: lighter;
}
.product_info_container .box .content0 .right .main .ite .le i {
  color: #939393;
  font-size: 14px;
}
.product_info_container .box .content0 .right .main .ite .ri {
  font-size: 14px;
  color: #939393;
  font-weight: lighter;
}
.product_info_container .box .content1 {
  margin-top: 0.43rem;
}
.product_info_container .box .content1 .left p {
  font-weight: bold;
  font-size: 16px;
  background: linear-gradient(to right, #0182ab, #2d61b7);
  color: #fff;
  width: 116px;
  line-height: 0.3rem;
  border-radius: 0.3rem;
  text-align: center;
}
.product_info_container .box .content1 .right {
  font-size: 14px;
  line-height: 26px;
  font-weight: lighter;
  width: 83%;
  text-align: justify;
  color: ghostwhite;
}
.product_info_container .box .content2 {
  margin-top: 0.48rem;
}
.product_info_container .box .content2 .left p {
  font-weight: bold;
  font-size: 16px;
  background: linear-gradient(to right, #0182ab, #2d61b7);
  color: #fff;
  width: 116px;
  line-height: 0.3rem;
  border-radius: 0.3rem;
  text-align: center;
}
.product_info_container .box .content2 .right {
  width: 83%;
  box-sizing: border-box;
  padding-left: 0.26rem;
}
.product_info_container .box .content2 .right img {
  max-width: 100%;
}
.CooperativePartner {
  /* background: url(https://jsm-1305290939.cos.ap-chengdu.myqcloud.com/images/bg4.png) no-repeat 50% 35%; */
  background: linear-gradient(to top, #001632, #030624);
  background-size: cover;
}
.CooperativePartner .box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px ;
  padding: 0 0.6rem;
}
.CooperativePartner .box .item {
  display: flex;
  align-items: center;
  background: #fff;
  height: 1.5rem;
  border-radius: 8px;
   overflow:hidden ;
}
.CooperativePartner .box .item p {
  margin: auto;
 
}
.CooperativePartner .box .item p img {
  max-width: 100%;
  max-height: 100%;
  transition: 0.4s;
}
.CooperativePartner .box .item:hover p img {
  transform: scale(1.1);
}
.caseInfoContainer {
  background: url(https://jsm-1305290939.cos.ap-chengdu.myqcloud.com/images/bg4.png) no-repeat 50% 35%;
  background-size: cover;
}
.caseInfoContainer .box {
  padding: 0 0.9rem 0 0.2rem;
  background: #fff;
}
.caseInfoContainer .box .boxLeft {
  width: 2.2rem;
}
.caseInfoContainer .box .boxLeft img {
  width: 100%;
}
.caseInfoContainer .box .boxRight {
  flex: 1;
  padding-left: 0.2rem;
  padding-bottom: 0.72rem;
  box-sizing: border-box;
}
.caseInfoContainer .box .boxRight .content0 .main {
  margin-top: 0.48rem;
  border-bottom: 2px solid #008022;
  padding-bottom: 0.11rem;
}
.caseInfoContainer .box .boxRight .content0 .main h5 {
  font-size: 0.24rem;
  font-weight: bold;
}
.caseInfoContainer .box .boxRight .content0 .main .ite {
  margin-top: 0.16rem;
}
.caseInfoContainer .box .boxRight .content0 .main .ite .le {
  color: #939393;
}
.caseInfoContainer .box .boxRight .content0 .main .ite .le a {
  font-size: 14px;
  color: #939393;
  font-weight: lighter;
}
.caseInfoContainer .box .boxRight .content0 .main .ite .le i {
  color: #939393;
  font-size: 14px;
}
.caseInfoContainer .box .boxRight .content0 .main .ite .ri {
  font-size: 14px;
  color: #939393;
  font-weight: lighter;
}
.caseInfoContainer .box .boxRight .content1 {
  padding: 0.34rem 0;
}
.caseInfoContainer .box .boxRight .content1 p {
  font-size: 14px;
  line-height: 26px;
}
.caseInfoContainer .box .boxRight .content2 .boxTop {
  overflow: hidden;
}
.caseInfoContainer .box .boxRight .content2 .boxTop .button {
  width: 1.25rem;
  background: #efefef;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.caseInfoContainer .box .boxRight .content2 .boxTop .button p {
  margin: auto;
}
.caseInfoContainer .box .boxRight .content2 .boxTop .next p {
  transform: rotate(180deg);
}
.caseInfoContainer .box .boxRight .content2 .boxTop .banner {
  margin: 0 18px;
  flex: 1;
  width: 1px;
}
.caseInfoContainer .box .boxRight .content2 .boxTop .banner .swiper {
  width: 100%;
}
.caseInfoContainer .box .boxRight .content2 .boxTop .banner .swiper-slide img {
  width: 100%;
}
.caseInfoContainer .box .boxRight .content2 .boxBot {
  text-align: center;
  margin-top: 0.26rem;
}
.caseInfoContainer .box .boxRight .content2 .boxBot div span {
  background: none;
  border: 1px solid #008022;
  opacity: 1;
  transition: 0.4s;
  width: 4px;
  height: 4px;
  margin: 0 0.06rem;
}
.caseInfoContainer .box .boxRight .content2 .boxBot div .swiper-pagination-bullet-active {
  background: #008022;
}
.caseInfoContainer .box .boxRight .content3 {
  margin-top: 0.33rem;
}
.caseInfoContainer .box .boxRight .content3 h5 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(to right, #0182ab, #2d61b7);
  display: inline-block;
  padding: 0 25px;
  line-height: 30px;
  border-radius: 15px;
}
.caseInfoContainer .box .boxRight .content3 .list {
  margin-top: 0.2rem;
}
.caseInfoContainer .box .boxRight .content3 .list p {
  font-size: 14px;
  font-family: 'HelveticaNowText-Light';
  padding: 0.15rem 0;
  border-bottom: 1px dashed #008022;
  line-height: 0.26rem;
  position: relative;
}
.caseInfoContainer .box .boxRight .content3 .list p::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0.26rem;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #008022;
}
.newsInfoContainer {
  background: url(https://jsm-1305290939.cos.ap-chengdu.myqcloud.com/images/bg7.png) no-repeat 50% 35%;
  background-size: cover;
  padding-top: 0.48rem;
}
.newsInfoContainer .box {
  padding: 0.66rem 0.7rem 0.7rem 0.7rem;
  background: #fff;
}
.newsInfoContainer .box .boxLeft {
  width: 2.42rem;
}
.newsInfoContainer .box .boxLeft img {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.newsInfoContainer .box .boxRight {
  padding-left: 0.36rem;
  flex: 1;
}
.newsInfoContainer .box .boxRight .content0 {
  height: 1.42rem;
}
.newsInfoContainer .box .boxRight .content0 .main {
  border-bottom: 2px solid #008022;
  padding-bottom: 0.11rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100% - 12px);
}
.newsInfoContainer .box .boxRight .content0 .main h5 {
  font-size: 0.24rem;
  font-weight: bold;
}
.newsInfoContainer .box .boxRight .content0 .main .ite .le {
  color: #939393;
  max-width: 80%;
}
.newsInfoContainer .box .boxRight .content0 .main .ite .le a {
  font-size: 14px;
  color: #939393;
  font-weight: lighter;
}
.newsInfoContainer .box .boxRight .content0 .main .ite .le i {
  color: #939393;
  font-size: 14px;
}
.newsInfoContainer .box .boxRight .content0 .main .ite .ri {
  font-size: 14px;
  color: #939393;
  font-weight: lighter;
}
.newsInfoContainer .box .boxRight .content1 {
  font-family: 'HelveticaNowText-Light';
  font-size: 14px;
  line-height: 22px;
  margin-top: 0.42rem;
}
.newsInfoContainer .box .boxRight .content1 h5 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(to right, #0182ab, #2d61b7);
  display: inline-block;
  padding: 0 25px;
  line-height: 30px;
  border-radius: 15px;
}
.newsInfoContainer .box .boxRight .content1 p img {
  max-width: 100%;
  width: auto !important;
  height: auto !important;
  margin: auto;
}
.newsInfoContainer .box .boxRight .content1 p span {
  font-family: 'HelveticaNowText-Light';
  color: #939393;
  font-size: 14px;
  display: inline-block;
  margin-top: 8px;
}
.newsInfoContainer .box .boxRight .content2 {
  margin-top: 0.32rem;
}
.newsInfoContainer .box .boxRight .content2 .div {
  padding: 0.28rem 0;
  border-top: 1px dashed #008022;
}
.newsInfoContainer .box .boxRight .content2 .div h6 {
  font-weight: bold;
  font-size: 16px;
  color: #008022;
  margin-bottom: 16px;
}
.newsInfoContainer .box .boxRight .content2 .div div,
.newsInfoContainer .box .boxRight .content2 .div p {
  font-family: 'HelveticaNowText-Light';
  font-size: 14px;
  line-height: 22px;
}
.searchContainer {
  background: url(https://jsm-1305290939.cos.ap-chengdu.myqcloud.com/images/bg7.png) no-repeat 50% 35%;
  background-size: cover;
  padding-top: 0.48rem;
}
.searchContainer .box {
  background: #fff;
  padding: 0.88rem 1.6rem 1.1rem;
}
.searchContainer .box .boxTop .content0 {
  font-weight: bold;
  font-size: 20px;
}
.searchContainer .box .boxTop .content1 {
  margin: 10px 0;
  display: inline-block;
}
.searchContainer .box .boxTop .content1 form {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.searchContainer .box .boxTop .content1 input[type='text'] {
  display: block;
  width: 270px;
  height: 30px;
  box-sizing: border-box;
  border: 0;
  background: #fff;
  font-size: 16px;
  padding: 0 40px 0 10px;
  border: 1px solid #848484;
  border-radius: 5px;
}
.searchContainer .box .boxTop .content1 p {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: #008022;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.searchContainer .box .boxTop .content1 p img {
  cursor: pointer;
}
.searchContainer .box .boxTop .content1 p input {
  display: none;
}
.searchContainer .box .boxTop .content1 p label {
  margin: auto;
}
.searchContainer .box .boxTop .content2 {
  font-weight: lighter;
  font-size: 14px;
  color: #787878;
}
.searchContainer .box .boxBot .item {
  border-bottom: 1px solid #848484;
  position: relative;
}
.searchContainer .box .boxBot .item::after {
  content: '';
  width: 0%;
  position: absolute;
  height: 1px;
  background: #008022;
  left: 0;
  bottom: 0;
  transition: 0.4s ease-out;
}
.searchContainer .box .boxBot .item a {
  position: relative;
  display: block;
  padding: 0.68rem 0.9rem 0.68rem 0;
  box-sizing: border-box;
}
.searchContainer .box .boxBot .item a h5 {
  font-weight: bold;
  font-size: 0.2rem;
  color: #000;
  transition: 0.4s;
}
.searchContainer .box .boxBot .item a h6 {
  font-size: 14px;
  font-weight: lighter;
  margin-top: 0.18rem;
  color: #000;
}
.searchContainer .box .boxBot .item a .button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.searchContainer .box .boxBot .item a .button svg {
  width: 27px;
}
.searchContainer .box .boxBot .item a .button img {
  width: 27px;
}
.searchContainer .box .boxBot .item:hover::after {
  width: 100%;
}
.searchContainer .box .boxBot .item:hover a h5 {
  color: #008022;
}



.honorZZ {
  padding: 0.82rem 0 0.68rem;
  background: #d0dce9;
}
.honorZZ .desc {
  font-size: 14px;
  line-height: 1.8;
  text-align: center;
  margin-top: 0.4rem;
}
.honorCertificate {
  background: url(https://jsm-1305290939.cos.ap-chengdu.myqcloud.com/images/bg4.png) no-repeat 50% 35%;
  background-size: cover;
}
.honorCertificate .box {
  margin-top: 0.6rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}
.honorCertificate .box .item {
  padding: 0.3rem;
  background: #eef2f3;
  position: relative;
  overflow: hidden;
}
.honorCertificate .box .item .img {
  height: 1.64rem;
  display: flex;
  align-items: center;
}
.honorCertificate .box .item .img img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.honorCertificate .box .item .position {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  line-height: 0.42rem;
  transition: 0.4s;
  transform: translateY(100%);
  font-size: 0.16rem;
  background: linear-gradient(to right, #0182ab, #2d61b7);
}
.honorCertificate .box .item:hover .position {
  transform: translateY(0%);
}
.honorAptitude {
  background: linear-gradient(to bottom, #d4e0eb, #aec4da);
}
.honorAptitude .box .banner {
  margin-top: 0.9rem;
  position: relative;
  padding: 0 0.88rem;
}
.honorAptitude .box .banner .swiper-slide {
  background: #e7edf4;
  text-align: center;
  padding: 0.36rem 0;
}
.honorAptitude .box .banner .button > div {
  height: 100%;
  width: 0.66rem;
  display: flex;
  align-items: center;
  text-align: center;
  background: #e7edf4;
  position: absolute;
  top: 0;
  cursor: pointer;
  transition: 0.4s;
}
.honorAptitude .box .banner .button > div i {
  font-size: 0.3rem;
  color: #008022;
  margin: auto;
  transition: 0.4s;
}
.honorAptitude .box .banner .button > div:hover {
  background: #008022;
}
.honorAptitude .box .banner .button > div:hover i {
  color: #fff;
}
.honorAptitude .box .banner .button .prev {
  left: 0;
}
.honorAptitude .box .banner .button .next {
  right: 0;
}



@media screen and (max-width: 1660px) {
    .about_contaiiner .box .right .content2 {
        /*height: 1.8rem;*/
        overflow: auto;
    	overflow-x: hidden;
    	overflow-y: auto;
    	padding-right: 10px;
    }
    
    .about_contaiiner .box .right .content2::-webkit-scrollbar {
	/*滚动条整体样式*/
    	width: 1px;
    	/*高宽分别对应横竖滚动条的尺寸*/
    	height: 2px;
    }
    
    .about_contaiiner .box .right .content2::-webkit-scrollbar-thumb {
    	/*滚动条里面小方块*/
    	border-radius: 2px;
    	/* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
    	background: rgba(0, 0, 0, 0.7);
    }
    
    .about_contaiiner .box .right .content2::-webkit-scrollbar-track {
    	/*滚动条里面轨道*/
    	/* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
    	border-radius: 2px;
    	background: rgba(0, 0, 0, 0.1);
    }
    .fzlc_container .box .list .item:nth-child(2n) .ite::before {
        left: -0.66rem;
    }
    .fzlc_container .box .list .item .ite::before {
        right: -0.7rem;
    }
        
    
}


@media screen and (max-width: 1500px) {
  .about_contaiiner .box .left p {
    height: 100%;
  }
  .about_contaiiner .box .left p img {
    height: 100%;
    object-fit: cover;
  }
  .home_news_container .box .left a .img img {
    height: 2.8rem;
  }
  .news_container .box1 .item a .info ._ri h5,
  .home_news_container .box .left a .info ._ri h5 {
    margin-top: 0.07rem;
  }
  .newsInfoContainer .box .boxRight .content1 h5,
  .caseInfoContainer .box .boxRight .content3 h5 {
    font-size: 14px;
  }
}
@media screen and (max-width: 1366px) {
  /*.innerBanner .position {*/
  /*  left: 4rem;*/
  /*  top: 1rem;*/
  /*}*/
  /*.innerBanner .l416 {*/
  /*  left: 3.16rem;*/
  /*}*/
}
@media screen and (max-width: 1200px) {
    
    .honorCertificate .box {
		grid-template-columns: repeat(3, 1fr);
	}
    .home_cueromer_container .box{
        border-radius: 0;
    }
    .news_container .box1 .item a .img img{
        height: auto;
    }
    .news_container .box .banner a .right .content2{
        display: -webkit-box;
    	-webkit-box-orient: vertical;
    	-webkit-line-clamp: 2;
    	overflow: hidden;
    	text-align: justify;
    	text-justify: distribute-all-lines;
    }
    .case_filter_container .box .list p{
        margin-bottom: 0.14rem;
    }
    
    
      .select_nav_container_center .box .item:last-child{
      border-left: 2px solid #bed0e0;
    }
    
    
    
  .header_container {
    display: none;
  }
  .innerBanner .position {
    left: 5vw;
    top: 1rem;
  }
  .innerBanner .img img {
    height: 3.6rem;
    object-fit: cover;
  }
  .innerBanner .position h5 {
    font-size: 0.36rem;
    letter-spacing: 0.15rem;
  }
  .about_contaiiner .box .left {
    width: 100%;
  }
  .about_contaiiner .box .right {
    width: 100%;
    padding-top: 37px;
  }
  .about_contaiiner .box1 .list {
    flex-wrap: wrap;
  }
  .about_contaiiner .box1 .list .item {
    flex: 1 48%;
    box-sizing: border-box;
  }
  .about_contaiiner .box2 {
    flex-flow: column;
  }
  .fzlc_container .box .list .item .ite {
    width: 100%;
  }
  .fzlc_container .box .list {
    padding-left: 10vw;
  }
  .fzlc_container .box .list .item {
    margin-bottom: 0.2rem;
  }
  .fzlc_container .box .list::after {
    left: 0;
  }
  .fzlc_container .box .list {
    padding-bottom: 0.8rem;
  }
  .fzlc_container .box .list .item .ite::before {
    right: auto;
    left: -10.3vw;
  }
  .fzlc_container .box .list .item .ite::after {
    right: auto;
    left: -18vw;
    width: 0.74rem;
  }
  .footer_container .box .boxLeft {
    width: 100%;
    text-align: center;
  }
  .footer_container .box .boxLeft .content0 {
    display: none;
  }
  .footer_container .box .boxRight {
    display: none;
  }
  .footer_container .box .boxLeft .content1 {
    margin-top: 0;
  }
  .case_container .pc {
    display: none;
  }
  .case_container .phone {
    display: block;
  }
  .case_container .box .list {
    grid-template-columns: repeat(4, 1fr);
  }
  .case_container .box .list .item {
    height: 2.05rem;
    width: 1.8rem;
  }
  .case_container .box {
    padding: 1rem 0 1rem;
  }
  .contact_ewm_container .box .item .text img {
    display: none;
  }
  .contact_ewm_container .box .item .ewm img {
    width: 1.4rem;
  }
  .contact_ewm_container .box .item {
    gap: 0.3rem;
  }
  .home_banner_container .banner .swiper-container .swiper-slide .position .main .cnTitle {
    font-size: 0.46rem;
    letter-spacing: 0.1rem;
    margin-top: 0.32rem;
  }
  .home_banner_container .banner .swiper-container .swiper-slide .img img {
    height: 5rem;
    object-fit: cover;
  }
  .home_banner_container .banner .swiper-container .swiper-slide .position .main {
    padding: 0;
  }
  .home_product .box {
    grid-template-columns: repeat(2, 1fr);
  }
  .home_technical .box {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }
  .home_news_container .box .left {
    display: none;
  }
  .home_news_container .box .right {
    width: 100%;
  }
  .home_cueromer_container .box {
    width: 90vw;
    height: auto;
    padding-bottom: 0.5rem;
    background-color: #fff;
  }
  .home_cueromer_container .box .position .main {
    padding: 0.7rem 0.6rem 0;
  }
  .home_cueromer_container .box .position .main .list {
    grid-template-columns: repeat(4, 1fr);
  }
  .home_cueromer_container .box .position .main .list .item {
    height: 1.14rem;
  }
  .home_cueromer_container .box .img {
    display: none;
  }
  .home_cueromer_container .box .position {
    position: initial;
  }
  .join_container .box .list .item a .right .top {
    flex-wrap: wrap;
  }
  .join_container .box .list .item a .right .top .left {
    width: 100%;
  }
  .join_container .box1 .list {
    grid-template-columns: repeat(2, 1fr);
  }
  .join_container .box .list .item a > .right .right {
    flex: 1 100%;
    align-items: center;
    padding: 0;
    margin-left: 0 !important;
    margin-top: 5px;
  }
  .join_container .box .list .item a > .right .top .right span {
    line-height: 22px;
    font-size: 12px;
  }
  .jzg_container .box {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.4rem;
    grid-row-gap: 0.4rem;
  }
  .join_container .box1 .list .item .bot {
    font-size: 0.22rem;
  }
  .news_container .box .banner a .left {
    width: 100%;
  }
  .news_container .box .banner a .right {
    width: 100%;
    padding-bottom: 1rem;
  }
  .news_container .box .banner a .right .contetn0 p {
    font-size: 0.2rem;
  }
  .news_container .box1 {
    grid-template-columns: repeat(2, 1fr);
  }
  .select_nav_container.select_nav_container_5 .box .item {
    width: 33.3333%;
  }
  .product_info_container .box {
    padding: 0 0.4rem 0.5rem;
  }
  .product_info_container .box .content0 .right {
    width: 100%;
  }
  .product_info_container .box .content1 .right,
  .product_info_container .box .content2 .right {
    width: 100%;
    margin-top: 0.3rem;
  }
  .product_info_container .box .content1 .left p,
  .product_info_container .box .content2 .left p {
    line-height: 0.4rem;
  }
  .product_container .box {
    grid-template-columns: repeat(2, 1fr);
  }
  .select_nav_container .box .item {
    width: 33.333%;
  }
  .search_container {
    top: 0px;
    z-index: 8;
  }
  .home_ServiceProvider_container .box .banner {
    width: 90vw;
    margin: 35px auto 0;
  }
  .home_ServiceProvider_container .box .banner .swiper-container .swiper-slide .position .left img {
    width: 32px;
  }
  .home_ServiceProvider_container .box .banner .img_ {
    background: linear-gradient(180deg, #0182ab, #2d61b7);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  }
  .home_ServiceProvider_container .box .banner .img_ img {
    opacity: 0;
  }
  .home_ServiceProvider_container .box .banner .swiper-container {
    width: 100%;
  }
  .home_ServiceProvider_container .box .banner .swiper-container .swiper-slide .position {
    height: calc(100%);
  }
  .home_ServiceProvider_container .box .banner .swiper-container .swiper-slide .position .left img {
    width: 39px;
  }
  .home_ServiceProvider_container .box .banner .swiper-container .swiper-slide .position .right h5 {
    font-size: 0.24rem;
    margin-top: 2px;
  }
  .home_ServiceProvider_container .box .banner .button div {
    top: 132%;
  }
  .home_ServiceProvider_container .box .banner .button .prev {
    left: 0;
  }
  .home_ServiceProvider_container .box .banner .button .next {
    right: 0;
  }
  .newsInfoContainer .box {
    padding: 0.46rem 0.3rem 0.5rem 0.3rem;
  }
  .newsInfoContainer .box .boxRight .content0 .main h5 {
    font-size: 0.2rem;
    margin-bottom: 0.3rem;
  }
  .newsInfoContainer .box .boxRight .content0 .main {
    height: auto;
  }
  .newsInfoContainer .box .boxRight .content1 {
    margin-top: 0.72rem;
  }
  .newsInfoContainer .box .boxLeft {
    width: 1.92rem;
  }
  .searchContainer .box {
    padding: 0.58rem 0.3rem 0.7rem;
  }
  .searchContainer .box .boxBot .item a {
    padding: 0.48rem 0.9rem 0.48rem 0;
  }
  .searchContainer .box .boxBot .item a .button img {
    width: 24px;
  }
  .CooperativePartner .box {
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
  }
  .caseInfoContainer .box .boxLeft {
    width: 2rem;
  }
  .caseInfoContainer .box .boxRight .content0 .main h5 {
    font-size: 0.2rem;
  }
  .caseInfoContainer .box .boxRight .content2 .boxTop .button {
    width: 0.45rem;
  }
  .caseInfoContainer .box .boxRight .content2 .boxTop .button p img {
    width: 10px;
  }
  .caseInfoContainer .box .boxRight .content2 .boxBot {
    margin-top: 0.16rem;
  }
}
@media screen and (max-width: 669px) {
    .home_ServiceProvider_container .box .banner1 {
        width: 78vw;
    }
    
    
   .home_ServiceProvider_container .box .banner1 .button .prev {
        left: -0.4rem;
    }
    .home_ServiceProvider_container .box .banner1 .button .next {
        right: -0.4rem;
    }
    
    .honorCertificate .box {
		grid-template-columns: repeat(2, 1fr);
	}
	.honorCertificate .box .item {
		padding: 0.2rem;
	}

	.honorCertificate .box .item .img {
		height: 1.54rem;
	}
	.honorZZ .desc {
		font-size: 13px;
	}

  .home_banner_container .banner .swiper-container .swiper-slide .position .main .cnTitle {
    font-size: 0.32rem;
    letter-spacing: 4px;
    margin-top: 0.22rem;
  }
  .home_banner_container .banner .swiper-container .swiper-slide .img img {
    height: 3.6rem;
  }
  .home_ServiceProvider_container .box .desc {
    padding: 0;
  }
  .title_container h5 {
    font-size: 0.3rem;
  }
  .home_product .box {
    grid-template-columns: repeat(1, 1fr);
  }
  .p104_ {
    padding-bottom: 0.84rem;
  }
  .home_technical .box .item .right p {
    line-height: 16px;
  }
  .home_technical .box .item .right {
    padding: 0 0.16rem;
  }
  .home_news_container .box .right {
    flex-wrap: wrap;
    gap: 10px;
  }
  .home_news_container .box .right .item {
    width: 100%;
    padding-left: 0;
  }
  .home_cueromer_container .box .position .main {
    padding: 0.2rem;
  }
  .home_cueromer_container .box .position .main .list {
    grid-template-columns: repeat(3, 1fr);
  }
  .innerBanner .img img {
    height: 3.2rem;
  }
  .innerBanner .position h5 {
    font-size: 0.32rem;
    letter-spacing: 5px;
  }
  .select_nav_container .box .item {
    width: 50%;
  }
  .select_nav_container .box .item a {
    font-size: 14px;
    line-height: 0.98rem;
  }
  .fzlc_container .box .list .item .ite::before,
  .fzlc_container .box .list .item .ite::after,
  .fzlc_container .box .list::after {
    display: none;
  }
  .fzlc_container .box .list {
    padding-left: 0;
  }
  .fzlc_container .box .list .item .ite .ri .content0 .desc {
    margin-top: 0.12rem;
    font-size: 12px;
  }
  .fzlc_container .box .list .item .ite .le {
    width: 2.06rem;
  }
  .fzlc_container .box .list .item .ite .le img {
    height: 100%;
    object-fit: cover;
    
  }
  .fzlc_container .box .list .item .ite .ri {
    padding: 0.14rem 0.24rem;
  }
  .select_nav_container.select_nav_container_center .box .item:first-child {
    border-left: 0;
  }
  .case_filter_container .box .list p a {
    font-size: 12px;
    padding: 0 0.24rem;
    min-width: 1.5rem;
  }
  .case_filter_container .box .list {
    justify-content: center;
  }
  .case_container .box .list .item {
    height: 1.75rem;
    width: 1.5rem;
  }
  .case_container .box .list {
    grid-template-columns: repeat(3, 1fr);
  }
  .contact_container .box {
    gap: 10px;
  }
  .contact_container .box .item {
    width: 100%;
  }
  .contact_ewm_container .box {
    gap: 10px;
  }
  .contact_ewm_container .box .item {
    width: 100%;
  }
  .contact_ewm_container .box .item:first-child .text {
    order: 0;
  }
  .contact_ewm_container {
    padding: 0.54rem 0;
  }
  .join_container .box .list .item a > .right {
    padding: 0;
    margin-top: 0.3rem;
  }
  .join_container .box .list .item a .icon {
    display: none;
  }
  .join_container .box .list .item a {
    flex-wrap: wrap;
  }
  .join_container .box .list .item a > .left {
    width: 100%;
  }
  .join_container .box .list .item a > .right .bot {
    font-size: 12px;
  }
  .jzg_container .box {
    grid-template-columns: repeat(1, 1fr);
  }
  .news_container .box .banner a .right .content1 {
    font-size: 0.2rem;
    line-height: 0.34rem;
  }
  .news_container .box .banner a .right .content2 {
    font-size: 12px;
    line-height: 20px;
    margin-top: 10px;
  }
  .news_container .box1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .news_container .box1 .item a .info ._le > div p {
    font-size: 14px;
  }
  .news_container .box1 .item a .info ._le {
    height: 1rem;
  }
  .select_nav_container.select_nav_container_5 .box .item {
    width: 50%;
  }
  .select_nav_container .box .item a img {
    margin-right: 15px;
    width: 0.3rem;
  }
  .product_info_container .box .content0 .left {
    width: 74px;
  }
  .product_info_container .box .content0 .left p img {
    max-width: 0.56rem;
  }
  .product_info_container .box .content1 .left p,
  .product_info_container .box .content2 .left p {
    font-size: 15px;
  }
  .product_info_container .box .content0 .right .main .ite .ri {
    width: 100%;
    margin-top: 0.3rem;
    font-size: 13px;
  }
  .product_info_container .box .content0 .right .main .ite .le a {
    font-size: 13px;
  }
  .product_info_container .box .content1 .right {
    font-size: 13px;
    line-height: 22px;
  }
  .product_info_container .box .content2 .right {
    padding-left: 0;
  }
  .product_info_container .box .content0 .right .main .ite .le {
    margin-top: 0.2rem;
  }
  .product_container .box {
    grid-template-columns: repeat(1, 1fr);
  }
  .search_container .box_search input[type='text'] {
    font-size: 14px;
  }
  .innerBanner .l416 {
    top: 0.7rem;
  }
  .innerBanner .position {
    width: 90vw;
  }
  .caseInfoContainer .box .boxLeft {
    width: 100%;
  }
  .caseInfoContainer .box {
    padding: 0 0.2rem 0 0.2rem;
    background: #fff;
  }
  .caseInfoContainer .box .boxRight .content0 .main .ite .le {
    width: 100%;
    margin-bottom: 0.2rem;
  }
  .caseInfoContainer .box .boxRight .content0 .main .ite .le a,
  .caseInfoContainer .box .boxRight .content0 .main .ite .ri {
    font-size: 13px;
  }
  .caseInfoContainer .box .boxRight .content1 p {
    font-size: 13px;
    line-height: 22px;
  }
  .caseInfoContainer .box .boxRight .content3 .list p {
    font-size: 13px;
  }
  .CooperativePartner .box {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
  .searchContainer .box .boxTop .content0 {
    font-size: 15px;
  }
  .searchContainer .box .boxTop .content1 {
    width: 100%;
  }
  .searchContainer .box .boxBot .item a h5 {
    font-size: 13px;
  }
  .searchContainer .box .boxBot .item a h6 {
    font-size: 12px;
  }
  .searchContainer .box .boxBot .item a {
    padding: 0.28rem 0.5rem 0.28rem 0;
  }
  .searchContainer .box .boxBot .item a h6 {
    margin-top: 0.1rem;
  }
  .searchContainer .box .boxBot .item a .button img {
    width: 14px;
  }
  .newsInfoContainer .box .boxLeft {
    width: 100%;
    margin-bottom: 0.4rem;
  }
  .newsInfoContainer .box .boxRight {
    padding-left: 0;
  }
  .newsInfoContainer .box .boxRight .content0 {
    height: auto;
  }
  .newsInfoContainer .box .boxRight .content0 .main .ite .le a {
    font-size: 12px;
  }
  .newsInfoContainer .box .boxRight .content0 .main .ite .ri {
    font-size: 12px;
    margin-top: 0.2rem;
    width: 100%;
  }
  .newsInfoContainer .box .boxRight .content1 {
    font-size: 13px;
  }
  .newsInfoContainer .box .boxRight .content1 p span {
    font-size: 12px;
  }
  .newsInfoContainer .box .boxRight .content1 h5,
  .caseInfoContainer .box .boxRight .content3 h5 {
    font-size: 13px;
  }
  .newsInfoContainer .box .boxRight .content2 .div h6 {
    font-size: 14px;
  }
  .newsInfoContainer .box .boxRight .content2 .div div,
  .newsInfoContainer .box .boxRight .content2 .div p {
    font-size: 13px;
  }
  
  
  .news_container .box .banner .page div span {
    width: 0.28rem;
    height: 0.28rem;
    line-height: 0.28rem;
    font-size: 12px;
}

.innerBanner .img img{
    margin-top: -1px;
}
}
/*# sourceMappingURL=./style.css.map */